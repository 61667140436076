<template>
	<WDialog v-model="dialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="$t('offers.delete_tasks_title')" @close="$emit('close', status)">
		<v-layout>
			<v-flex>{{ $t('offers.delete_tasks_message') }}</v-flex>
		</v-layout>
		<template v-slot:actions>
			<v-layout row>
				<v-spacer />
				<WButton flat @click="doDelete()">{{ $t('actions.yes') }}</WButton>
				<WButton flat @click="$emit('close', status)">{{ $t('actions.no') }}</WButton>
			</v-layout>
		</template>
	</WDialog>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
export default {
	name: 'TasksDeletion',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		status: {
			required: true,
			type: Object
		},
		projectId: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			dialog: true
		}
	},
	methods: {
		doDelete: function () {
			return this.service.deleteAllAssociatedTasks(this.projectId).then(() => {
				this.$emit('close', this.status)
			})
		}
	}
}
</script>
